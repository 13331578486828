import React, { useEffect, useState } from "react";
import firebase from "firebase/app"; // Import Firebase app (make sure to install it)
import { storage } from "./firebase"; // Import Firebase storage module
import { getStorage, ref, listAll } from "firebase/storage";
import { getDownloadURL } from "firebase/storage";

const beachesImages = [];
const exteriorImages = [];
const interiorImages = [];

// get all images from storage
const storageRef = ref(storage, "gs://la-casa-6125f.appspot.com/image_folder/beaches_folder");
listAll(storageRef)
  .then((res) => {
    res.items.forEach((itemRef) => {
      getDownloadURL(itemRef).then((url) => {
        beachesImages.push(url);
      });
    });
  })
  .catch((error) => {
    console.log(error);
  });

const storageRef2 = ref(
  storage,
  "gs://la-casa-6125f.appspot.com/image_folder/exterior_folder"
);
listAll(storageRef2)
  .then((res) => {
    res.items.forEach((itemRef) => {
      getDownloadURL(itemRef).then((url) => {
        exteriorImages.push(url);
      });
    });
  })
  .catch((error) => {
    console.log(error);
  });

const storageRef3 = ref(
  storage,
  "gs://la-casa-6125f.appspot.com/image_folder/interior_folder"
);
listAll(storageRef3)
  .then((res) => {
    res.items.forEach((itemRef) => {
      getDownloadURL(itemRef).then((url) => {
        interiorImages.push(url);
      });
    });
  })
  .catch((error) => {
    console.log(error);
  });

export const images = {
  //create 23 images for the slideshow
  beachesImages: beachesImages,
  exteriorImages: exteriorImages,
  interiorImages: interiorImages,
};

console.log(images);
