import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";
import "./slideshow.css";

const ImageSlideshow = ({imageCollection = []}) => {
  const [image1, setImage1] = useState(imageCollection[0]);
  const [image2, setImage2] = useState(imageCollection[1]);
  const [image3, setImage3] = useState(imageCollection[2]);
  const [image4, setImage4] = useState(imageCollection[3]);

  //switch images every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      nextImages();
    }, 8000);
    return () => clearInterval(interval);
  }, [image1, image2, image3, image4]);
  const nextImages = () => {
    const index = imageCollection.indexOf(image1);
    const index2 = imageCollection.indexOf(image2);
    const index3 = imageCollection.indexOf(image3);
    const index4 = imageCollection.indexOf(image4);
    if (index > imageCollection.length - 5) {
      //reset index
      setImage1(imageCollection[0]);
    } else {
      setImage1(imageCollection[index + 4]);
    }
    if (index2 > imageCollection.length - 4) {
      setImage2(imageCollection[1]);
    } else {
      setImage2(imageCollection[index2 + 4]);
    }
    if (index3 > imageCollection.length - 3) {
      setImage3(imageCollection[2]);
    } else {
      setImage3(imageCollection[index3 + 4]);
    }
    if (index4 > imageCollection.length - 2) {
      setImage4(imageCollection[3]);
    } else {
      setImage4(imageCollection[index4 + 4]);
    }
  };

  const { t } = useTranslation();

  return (
    <div className="slideshow-containerq6h7r3k9">
      <div className="slideshow-image-container-top">
        <div className="slideshow-image-container-1">
          <AnimatePresence mode="wait">
            <motion.img
              src={image1}
              alt="slideshow-image-1"
              className="slideshow-image-1"
              initial={{
                scale: 0.8,
                x: -100,
                opacity: 0,
                transition: { duration: 0.4 },
              }} // Added opacity for a fade-in effect
              animate={{ scale: 1, x: 0, opacity: 1 }}
              whileHover={{ scale: 1.1 }} // Hover effect, removed transition as it's not necessary here
              exit={{ scale: 0.8, opacity: 0, x: 100 }} // Changed exit scale and animation for consistency
              transition={{ duration: 0.4, type: "spring", stiffness: 80 }}
              key={image1}
            />
          </AnimatePresence>
        </div>
        <div className="slideshow-image-container-2">
          <AnimatePresence mode="wait">
            <motion.img
              src={image2}
              alt="slideshow-image-2"
              className="slideshow-image-2"
              initial={{
                scale: 0.8,
                x: -100,
                opacity: 0,
                transition: { duration: 0.8 },
              }} // Added opacity for a fade-in effect
              animate={{ scale: 1, x: 0, opacity: 1 }}
              whileHover={{ scale: 1.1 }} // Hover effect, removed transition as it's not necessary here
              exit={{ scale: 0.8, opacity: 0, x: 100 }} // Changed exit scale and animation for consistency
              transition={{ duration: 0.8, type: "spring", stiffness: 80 }}
              key={image2}
            />
          </AnimatePresence>
        </div>
      </div>
      <div className="slideshow-image-container-bottom">
        <div className="slideshow-image-container-3">
          <AnimatePresence mode="wait">
            <motion.img
              src={image3}
              alt="slideshow-image-3"
              className="slideshow-image-3"
              initial={{
                scale: 0.8,
                x: -100,
                opacity: 0,
                transition: { duration: 0.6 },
              }} // Added opacity for a fade-in effect
              animate={{ scale: 1, x: 0, opacity: 1 }}
              whileHover={{ scale: 1.1 }} // Hover effect, removed transition as it's not necessary here
              exit={{ scale: 0.8, opacity: 0, x: 100 }} // Changed exit scale and animation for consistency
              transition={{ duration: 0.6, type: "spring", stiffness: 80 }}
              key={image3}
            />
          </AnimatePresence>
        </div>
        <div className="slideshow-image-container-4">
          <AnimatePresence mode="wait">
            <motion.img
              src={image4}
              alt="slideshow-image-4"
              className="slideshow-image-4"
              initial={{
                scale: 0.8,
                x: -100,
                opacity: 0,
                transition: { duration: 1 },
              }} // Added opacity for a fade-in effect
              animate={{ scale: 1, x: 0, opacity: 1 }}
              whileHover={{ scale: 1.1 }} // Hover effect, removed transition as it's not necessary here
              exit={{ scale: 0.8, opacity: 0, x: 100 }} // Changed exit scale and animation for consistency
              transition={{ duration: 1, type: "spring", stiffness: 80 }}
              key={image4}
            />
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default ImageSlideshow;
