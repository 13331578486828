import React from "react";
import { useTranslation } from "react-i18next";
import { motion, easeInOut } from "framer-motion";
import "./info-card.css";
import ImageSlideshow from "./slideshow";
import { images } from '../../../components/imageCache'

const InfoCard = () => {
  return (
    <article className="infocards-container">
      <div className="infocard-container-1">
        <div className="infocard-text-1">
            <h3>Tranquil Seaside Oasis with Breathtaking Views</h3>
            <p>
            Escape to a tranquil seaside haven at this newly refurbished two-bedroom apartment in Ladera del Mar. Surrounded by picturesque landscapes and bathed in sunlight, it offers a serene retreat. Step onto the terrace to be captivated by sweeping views of the sea, creating the perfect backdrop for relaxation. With a charming town within walking distance, you'll have easy access to amenities while reveling in the peaceful surroundings.
            </p>
            </div>
        <div className="infocard-box-1">
        <ImageSlideshow imageCollection={images.beachesImages} />
        </div>
      </div>
      <div className="infocard-container-2">
        <div className="infocard-text-2">
            <h3>Modern Comforts for Seamless Living</h3>
            <p>
            Experience modern comfort and seamless living in this two-bedroom Ladera del Mar apartment. Designed for remote work and relaxation, it features fast internet and a dedicated workspace. The interior boasts a high-quality kitchen with modern appliances, a stylish bathroom, and spacious bedrooms with fitted wardrobes. Whether you're focusing on work or unwinding after a day in the sun, this apartment provides the perfect retreat for a balanced lifestyle.
            </p>
            </div>
        <div className="infocard-box-2">
        <ImageSlideshow imageCollection={images.interiorImages} />
        </div>
      </div>
    </article>
  );
};

export default InfoCard;
