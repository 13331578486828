import "./navbar.css";
import { motion } from "framer-motion";
import { Button, Menu, Dropdown } from "antd";
import { US, SE, DE } from "country-flag-icons/react/3x2";
import { useTranslation, Trans } from "react-i18next";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logoImage from "../media/logos/logo.png";
import Icon, {
  HomeOutlined,
  UserOutlined,
  SettingOutlined,
  InfoOutlined,
  DollarOutlined,
  GlobalOutlined,
  DownOutlined,
} from "@ant-design/icons";
const lngs = {
  en: { nativeName: "English" },
  se: { nativeName: "Svenska" },
  de: { nativeName: "Deutsch" },
};
function Navbar() {
  const { t, i18n } = useTranslation();
  const [currentLanguageIcon, setCurrentLanguageIcon] = useState(<US />);
  const userPreferredLanguage = navigator.language.split("-")[0];
  const [language, setLanguage] = useState("en");
  const languageItems = [
    {
      key: "en",
      label: (
        <div onClick={() => switchLanguage("en", <US />)}>
          <Button
            type="text"
            icon={
              <div className="flag">
                <US />
              </div>
            }
          >
            {lngs.en.nativeName}
          </Button>
        </div>
      ),
      icon: (
        <div className="flag">
          <US />
        </div>
      ),
    },
    {
      key: "se",
      label: (
        <div onClick={() => switchLanguage("se", <SE />)}>
            <Button
                type="text"
                icon={
                <div className="flag">
                    <SE />
                </div>
                }
            >
                {lngs.se.nativeName}
            </Button>
        </div>
      ),
      icon: (
        <div className="flag">
          <SE />
        </div>
      ),
    },
    {
      key: "de",
      label: (
        <div onClick={() => switchLanguage("de", <DE />)}>
            <Button
                type="text"
                icon={
                <div className="flag">
                    <DE />
                </div>
                }
            >
                {lngs.de.nativeName}
            </Button>
        </div>
      ),
      icon: (
        <div className="flag">
          <DE />
        </div>
      ),
    },
  ];

  //settings buttons, should include night mode, privacy policy, terms of service, etc.
  const settingsItems = [
    {
      key: "night-mode",
      label: (
        <Button type="text" icon={<moonIcon />}>
          {t("night-mode")}
        </Button>
      ),
    },
  ];

  useEffect(() => {
    // This function will run when the component is mounted (page loaded)
    initialFunction();
  }, []);
  const initialFunction = () => {
    console.log(userPreferredLanguage);
    switch (userPreferredLanguage) {
      case "en":
        switchLanguage("en", <US />);
        break;
      case "sv":
        switchLanguage("se", <SE />);
        break;
      case "de":
        switchLanguage("de", <DE />);
        break;
      default:
        switchLanguage("en", <US />);
        break;
    }
  };
  const switchLanguage = (language, icon) => {
    i18n.changeLanguage(language);
    setCurrentLanguageIcon(icon);
    setLanguage(language);
  };
  return (
    <div className="navbar-container">
      <div className="logo-container">
        <img src={logoImage} className="logo" alt="logo" />
      </div>
      <motion.div>
        <Menu mode="horizontal" className="navbar" selectable>
          <motion.div whileHover={{ scale: 1.1 }}>
            <Menu.Item key="home" icon={<HomeOutlined />}>
              <Link to="/">{t("home")}</Link>
            </Menu.Item>
          </motion.div>
          <motion.div whileHover={{ scale: 1.1 }}>
            <Menu.Item key="info" icon={<InfoOutlined />}>
              <Link to="/info">{t("info")}</Link>
            </Menu.Item>
          </motion.div>
          <motion.div whileHover={{ scale: 1.1 }}>
            <Menu.Item key="rates" icon={<DollarOutlined />}>
              <Link to="/rates">{t("rates")}</Link>
            </Menu.Item>
          </motion.div>
          <motion.div whileHover={{ scale: 1.1 }}>
            <Menu.Item key="contact" icon={<UserOutlined />}>
              <Link to="/contact">{t("contact")}</Link>
            </Menu.Item>
          </motion.div>
        </Menu>
      </motion.div>
      <div className="settings-container">
        <Dropdown
          className="language-dropdown"
          menu={{
            items: languageItems,
          }}
        >
          <Button
            className="language-button"
            shape="circle"
            icon={<GlobalOutlined />}
            type="text"
            ghost
          >
          </Button>
        </Dropdown>
        <motion.div whileHover={{ scale: 1.1 }}>
          <Dropdown
            className="settings-dropdown"
            menu={{
              items: settingsItems,
            }}
          >
            <Button className="setting-buttons" icon={<SettingOutlined />}>
              {t("settings")}
              <DownOutlined />
            </Button>
          </Dropdown>
        </motion.div>
      </div>
    </div>
  );
}

export default Navbar;
