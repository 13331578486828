import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "../pages/Home/App";
import Info from "../pages/Info/Info";
import ErrorPage from "../pages/Status-Pages/404";
import { AnimatePresence } from "framer-motion";
import Cookie from "./cookie"
import Gallery from "../pages/Gallery/gallery"

const Navigation = () => {
  return (
    <div>
      <Cookie />
    <Router>
      <AnimatePresence wait>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/info" element={<Info />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="*" element={<ErrorPage />} />
          <Route path="/404" element={<ErrorPage />} />
        </Routes>
      </AnimatePresence>
    </Router>
    </div>
  );
};

export default Navigation;
