// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDWNyUhy_1LP7E-vEdA47GidbWaE2iJTt0",
  authDomain: "la-casa-6125f.firebaseapp.com",
  projectId: "la-casa-6125f",
  storageBucket: "la-casa-6125f.appspot.com",
  messagingSenderId: "900806118404",
  appId: "1:900806118404:web:451f3ee001fb689596b951",
  measurementId: "G-6JN20Y61VT"
};


export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const storage = getStorage(app);
console.log("RUNNING FIREBASE");