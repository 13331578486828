import React from "react";
import { useTranslation } from "react-i18next";
import "./info.css";
import { Helmet } from "react-helmet";

function Info() {
  const { t } = useTranslation();
  return (
    <main>
      <Helmet>
        <title>{t("Info.title")}</title>
      </Helmet>
    </main>
  );
}

export default Info;
