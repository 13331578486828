import React from "react";
import {
  motion,
  easeInOut,
  useAnimation,
  useTime,
  useTransform,
} from "framer-motion";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Rate,Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";

import "./review.css";
import { use } from "i18next";

const Review = () => {
  const [currentReview, setCurrentReview] = useState(1);
  const [rotate, setRotate] = useState(500);
  const [position, setPosition] = useState(0);
  const time = useTime();
  const rotater = useTransform(time, [0, 5000], [0, rotate], { clamp: false });
  const items = [
    <div className="review-box-1">
      <div className="rating-box">
        <div className="rating-profile-icon">
          <Avatar
            size={64}
            icon={<UserOutlined />}
            className="profile-review-image"
            alt="profile-1"
          />
        </div>
        <div className="rating-stars">
          <div className="rating-name">
            <p>Luisa K.</p>
          </div>
          <div>
            <Rate disabled defaultValue={5} />
          </div>
        </div>
      </div>
      <div className="review-box-text">
        <p>
          "Very amazing flat with a super sunny terrace. The whole flat was very
          well equipped and has everything you might need during your stay. Also
          it was spotlessly clean and the communication with the owner
          beforehand was great and uncomplicated. We would return without any
          doubt!"
        </p>
      </div>
    </div>,
    <div className="review-box-1">
      <div className="rating-box">
        <div className="rating-profile-icon">
          <Avatar
            size={64}
            icon={<UserOutlined />}
            className="profile-review-image"
            alt="profile-2"
          />
        </div>
        <div className="rating-stars">
          <div className="rating-name">
            <p>Teddy</p>
          </div>
          <div>
            <Rate disabled defaultValue={5} />
          </div>
        </div>
      </div>
      <div className="review-box-text">
        <p>
          "it is worth planning your spain trip when Mikaels place is available.
          I've never seen such a great apartment with a view and hospitality.
          I'm definitely comming back. worth every cents."
        </p>
      </div>
    </div>,
    <div className="review-box-1">
      <div className="rating-box">
        <div className="rating-profile-icon">
          <Avatar
            size={64}
            icon={<UserOutlined />}
            className="profile-review-image"
            alt="profile-3"
          />
        </div>
        <div className="rating-stars">
          <div className="rating-name">
            <p>Paula M.</p>
          </div>
          <div>
            <Rate disabled defaultValue={5} />
          </div>
        </div>
      </div>
      <div className="review-box-text">
        <p>
          "Beautiful apartment. Stunning views. Gorgeous swimming pool. Our
          hosts could not have been more helpful arranging our airport transfers
          for us. Nothing was too much trouble for them. We cannot wait to visit
          again xxx"
        </p>
      </div>
    </div>,
    <div className="review-box-1">
      <div className="rating-box">
        <div className="rating-profile-icon">
          <Avatar
            size={64}
            icon={<UserOutlined />}
            className="profile-review-image"
            alt="profile-3"
          />
        </div>
        <div className="rating-stars">
          <div className="rating-name">
            <p>Paula M.</p>
          </div>
          <div>
            <Rate disabled defaultValue={5} />
          </div>
        </div>
      </div>
      <div className="review-box-text">
        <p>
          "Beautiful apartment. Stunning views. Gorgeous swimming pool. Our
          hosts could not have been more helpful arranging our airport transfers
          for us. Nothing was too much trouble for them. We cannot wait to visit
          again xxx"
        </p>
      </div>
    </div>,
    <div className="review-box-1">
      <div className="rating-box">
        <div className="rating-profile-icon">
          <Avatar
            size={64}
            icon={<UserOutlined />}
            className="profile-review-image"
            alt="profile-3"
          />
        </div>
        <div className="rating-stars">
          <div className="rating-name">
            <p>Paula M.</p>
          </div>
          <div>
            <Rate disabled defaultValue={5} />
          </div>
        </div>
      </div>
      <div className="review-box-text">
        <p>
          "Beautiful apartment. Stunning views. Gorgeous swimming pool. Our
          hosts could not have been more helpful arranging our airport transfers
          for us. Nothing was too much trouble for them. We cannot wait to visit
          again xxx"
        </p>
      </div>
    </div>,
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentReview((currentReview) =>
        currentReview === items.length - 1 ? 0 : currentReview + 1
      );
      updateMovement();
    }, 5000); // Change the interval as needed (in milliseconds)
    return () => clearInterval(interval);
  }, [items.length]);
  const updateMovement = () => {
    setRotate(0);
  };
  return (
    <div className="review-container-1">
      <div className="review-container">
        <div className="reviews-box-container">
          {items.map((item, index) => (
            <motion.div
              key={index}
              transition={{ duration: 0.5 }}
              className="review-box"
              whileHover={{ scale: 1.1 }}
              exit={{ opacity: 0 }}
            >
              {item}
            </motion.div>
          ))}
        </div>
        <div className="reviews-box-container">
          {items.map((item, index) => (
            <motion.div
              key={index}
              transition={{ duration: 0.5 }}
              className="review-box"
              whileHover={{ scale: 1.1 }}
              exit={{ opacity: 0 }}
            >
              {item}
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Review;
