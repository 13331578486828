import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "en",
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          home: "Home",
          info: "Info",
          rates: "Rates",
          gallery: "Gallery",
          contact: "Contact",
          settings: "Settings",
          description:
            "An ideal retreat for those seeking sun, serenity and beautiful surroundings but with the amenities of a town within walking distance. As you have fast internet and a workplace this is also the perfect spot for those going on a workation. A newly refurbished 2 bedroom bright and spacious apartment in the exclusive urbanization of Ladera del Mar. The accommodation has a high quality kitchen with appliances, a modern bathroom and 2 spacious bedrooms with fitted wardrobes. When you step out on the terrace you get a breathtaking view of the sea.",
        },
      },
      se: {
        translation: {
          home: "Hem",
          info: "Info",
          rates: "Priser",
          gallery: "Galleri",
          contact: "Kontakt",
          settings: "Inställningar",
          description:
            "En idealisk tillflyktsort för dem som söker sol, lugn och vackra omgivningar, men med stadens bekvämligheter inom gångavstånd. Eftersom du har snabbt internet och en arbetsplats är detta också den perfekta platsen för dem som åker på en arbetssemester. En nyrenoverad 2 sovrum ljus och rymlig lägenhet i den exklusiva urbaniseringen av Ladera del Mar. Boendet har ett högkvalitativt kök med vitvaror, ett modernt badrum och 2 rymliga sovrum med inbyggda garderober. När du kliver ut på terrassen får du en hisnande utsikt över havet.",
        },
      },
      de: {
        translation: {
          home: "Zuhause",
          info: "Info",
          rates: "Preise",
          gallery: "Galerie",
          contact: "Kontakt",
          settings: "Einstellungen",
          description:
            "Ein idealer Rückzugsort für alle, die Sonne, Ruhe und eine schöne Umgebung suchen, aber auch die Annehmlichkeiten einer Stadt zu Fuß erreichen möchten. Da Sie schnelles Internet und einen Arbeitsplatz haben, ist dies auch der perfekte Ort für diejenigen, die auf einen Arbeitsurlaub gehen. Ein neu renoviertes, helles und geräumiges Apartment mit 2 Schlafzimmern in der exklusiven Urbanisation Ladera del Mar. Die Unterkunft verfügt über eine hochwertige Küche mit Geräten, ein modernes Bad und 2 geräumige Schlafzimmer mit Einbauschränken. Wenn Sie auf der Terrasse treten Sie einen atemberaubenden Blick auf das Meer zu bekommen.",
        },
      },
    },
  });
