import React from "react";
import { Modal, Button } from "antd";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

function Cookie() {
    const [open, setOpen] = React.useState(false);
    const handleOk = () => {
        setOpen(false);
        localStorage.setItem("optedCookies", "true");
    };
    const handleCancel = () => {
        setOpen(false);
        localStorage.setItem("optedCookies", "true");
    };
    useEffect(() => {
        if (localStorage.getItem("optedCookies") === null || localStorage.getItem("optedCookies") === "false") {
            setOpen(true);
        }
    }, []);
  return (
    <div className="cookie-container">
      <Modal
        title="Cookie Policy"
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Reject-non-essential"
        cancelText="Accept-all"
        okButtonProps={{ type: "primary" }}
        cancelButtonProps={{ type: "primary" }}
      >
        <p>
          Casa Ladera Andaluz use cookies and similar technologies to
          provide you with a better experience. By accepting all cookies, you
          agree to our use of cookies to deliver and maintain our services and
          site, improve the quality of Casa Ladera Andaluz. By
          rejecting non-essential cookies, Casa Ladera Andaluz may still use certain cookies
          to ensure the proper functionality of our platform. For more
          information, please see our Cookie Notice and our Privacy Policy.
        </p>
      </Modal>
    </div>
  );
}

export default Cookie;
