import "./App.css";
import { motion, easeInOut, useAnimation, useScroll } from "framer-motion";
import { Button, DatePicker, Menu, Space, Dropdown, Carouse, Divider } from "antd";
import { US, SE, DE } from "country-flag-icons/react/3x2";
import { useTranslation, Trans } from "react-i18next";
import { useState, useEffect } from "react";
import Navbar from "../../components/navbar";
import ImageSlideshow from "./components/slideshow";
import InfoCard from "./components/info-card";
import { initializeApp } from "firebase/app";
import Review from "./components/review";
import { Helmet } from "react-helmet";
import { images } from '../../components/imageCache'

import { getAnalytics } from "firebase/analytics";

import Icon, {
  HomeOutlined,
  UserOutlined,
  SettingOutlined,
  PictureOutlined,
  InfoOutlined,
  DollarOutlined,
  GlobalOutlined,
  DownOutlined,
} from "@ant-design/icons";
const lngs = {
  en: { nativeName: "English" },
  se: { nativeName: "Svenska" },
  de: { nativeName: "Deutsch" },
};
function App() {
  const { t, i18n } = useTranslation();
  const [currentLanguageIcon, setCurrentLanguageIcon] = useState(<US />);
  const userPreferredLanguage = navigator.language.split("-")[0];
  const [language, setLanguage] = useState("en");
  const controls = useAnimation();
  const [currentSection, setCurrentSection] = useState(0);
  useEffect(() => {
    // This function will run when the component is mounted (page loaded)
    initialFunction();
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const section2Offset = document.getElementById("section-2").offsetTop;

      if (scrollY >= section2Offset) {
        controls.start({ opacity: 1, y: 0 });
      } else {
        controls.start({ opacity: 0, y: 100 });
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [controls]);

  const { scrollY } = useScroll();

useEffect(() => {
  const sectionPositions = [
    0,
    document.getElementById("section-1").offsetTop,
    document.getElementById("section-2").offsetTop,
    document.getElementById("section-3").offsetTop
  ];

  const updateCurrentSection = () => {
    const scrollPosition = scrollY.get();
    const sectionIndex = sectionPositions.findIndex(
      position => scrollPosition < position
    );
    setCurrentSection(sectionIndex === -1 ? sectionPositions.length - 1 : sectionIndex - 1);
  };

  updateCurrentSection();
  scrollY.onChange(updateCurrentSection);
}, [scrollY]);

  const initialFunction = () => {
    console.log(userPreferredLanguage);
    switch (userPreferredLanguage) {
      case "en":
        switchLanguage("en", <US />);
        break;
      case "sv":
        switchLanguage("se", <SE />);
        break;
      case "de":
        switchLanguage("de", <DE />);
        break;
      default:
        switchLanguage("en", <US />);
        break;
    }
  };
  const switchLanguage = (language, icon) => {
    i18n.changeLanguage(language);
    setCurrentLanguageIcon(icon);
    setLanguage(language);
  };
  return (
    <section>
      <header>
        <Helmet>
          <title>Homepage | Casa Ladera Andaluz</title>
        </Helmet>
        <nav class="navbar-header">
          <Navbar />
        </nav>
      </header>
      <main>
        <motion.article
          class="App"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, transition: { ease: easeInOut, duration: 0.5 } }}
        >
          <section class="content-container">
            <div class="layout-container">
              <section id="section-1">
                <header>
                  <h1 class="title">Casa Ladera Andaluz</h1>
                </header>
                <hr />
                <article class="text-slideshow">
                  <section class="side-title">
                    <hgroup>
                      <h2 class="side-title-text">
                        Discover the Perfect Blend of Nordic and Spanish Design
                        in Our Modern House Rental
                      </h2>
                      <hr />
                      <h3 class="immerse-text">
                        Immerse Yourself in the Spanish Culture and Experience
                        the Best of Nerja
                      </h3>
                    </hgroup>
                    <div class="text-book-buttons">
                      <motion.div whileHover={{ scale: 1.1 }}>
                        <Button className="book-button" type="primary">
                          Book Your Stay Now
                        </Button>
                      </motion.div>
                      <motion.div whileHover={{ scale: 1.1 }}>
                        <Button className="book-button" type="primary">
                          Contact Us
                        </Button>
                      </motion.div>
                    </div>
                  </section>
                  <figure class="side-image">
                    <ImageSlideshow imageCollection={images.exteriorImages} />
                  </figure>
                </article>
              </section>
              <Divider />
              <section id="section-2">
                <header>
                  <h1 class="title">A Beautiful View</h1>
                </header>
                <InfoCard />
              </section>
              <Divider />
              <section id="section-3">
                <header>
                  <h1 class="title">Read what our guests say</h1>
                </header>
                <Review />
              </section>
            </div>
            <aside id="overlay"></aside>
          </section>
        </motion.article>
      </main>
      <footer></footer>
    </section>
  );
}

export default App;
