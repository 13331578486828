import React, { useState, useEffect } from "react";
import { Carousel, Radio, Image } from "antd";
import { useTranslation } from "react-i18next";
import "./gallery.css";
import { Helmet } from "react-helmet";
import {storage} from "../../components/firebase";
import { getStorage, ref, listAll } from "firebase/storage";
import { getDownloadURL } from "firebase/storage";
import { use } from "i18next";

function Info() {
  const { t } = useTranslation();
  const [imageList, setImageList] = useState([]);
    useEffect(() => {
    // This function will run when the component is mounted (page loaded)
    initialFuction();
    }, []);
  const initialFuction = () => {
    console.log("initial function");
    const storage = getStorage();
    const storageRef = ref(storage, 'gs://la-casa-6125f.appspot.com/image_folder/exterior_folder');
    listAll(storageRef)
      .then((res) => {
        res.items.forEach((itemRef) => {
          getDownloadURL(itemRef).then((url) => {
            setImageList((imageList) => [...imageList, url]);
          });
        });
      })
      .catch((error) => {
        console.log(error);
      });
      console.log(imageList);
  };
  return (
    <section>
      <header>
        <Helmet>
          <title>{t("Info.title")}</title>
        </Helmet>
      </header>
      <main className="gallery-main">
        <section className="gallery-section">
        <div>
        <Carousel className="gallery-carousel" dotPosition="top">
          {imageList.map((image) => (
            <div>
              <Image.PreviewGroup
                items={imageList.map((image) => ({
                  src: image,
                }))}
              >
                <Image src={image} />
              </Image.PreviewGroup>
            </div>
          ))}
        </Carousel>
        </div>
        <div></div>
        </section>
      </main>
    </section>
  );
}

export default Info;
