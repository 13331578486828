import React from "react";
import { Button, Result } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
function ErrorPage() {
  const { t } = useTranslation();
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button type="primary">
          <Link to="/">{t("home")}</Link>
        </Button>
      }
    />
  );
}
export default ErrorPage;
